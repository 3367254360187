import { DietType } from 'src/gqlReactTypings.generated.d';
import { PreferencesForm } from 'src/pages/consumer/meal-plans/complete-meal-intake/preferences/types';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { createSelectors } from './create-selectors';

interface Actions {
  setFormData: (values: PreferencesForm) => void;
  clearFormData: () => void;
  getFormData: () => PreferencesForm;
}

interface State {
  formData: PreferencesForm;
}

const INITIAL_FORM_DATA: PreferencesForm = {
  adults: 0,
  bigEaters: 0,
  littleEaters: 0,
  deliveryDate: undefined,
  deliveryWindowId: undefined,
  dietType: DietType.Omnivore,
  proteinPreferences: [],
  anyDietaryRestrictions: false,
  replacementDietaryRestrictions: [],
  menuDietaryRestrictions: [],
  cuisinePreferences: [],
  moreInfo: undefined,
  mealsPerWeek: undefined,
  mealPlanType: undefined,
  deliveryTimeWindowStart: undefined,
  deliveryTimeWindowEnd: undefined,
  reusablePackaging: undefined,
};

const INITIAL_STATE = {
  formData: INITIAL_FORM_DATA,
};

export const useCompleteMealIntakeStore = create(
  persist<State & Actions>(
    (set, get) => ({
      ...INITIAL_STATE,
      setFormData: (values) => set({ formData: { ...get().formData, ...values } }),
      clearFormData: () => set({ formData: INITIAL_FORM_DATA }),
      getFormData: () => get().formData,
    }),
    {
      name: 'complete-meal-intake-store',
      getStorage: () => localStorage,
    }
  )
);

export const useCompleteMealIntakeStoreSelectors = createSelectors(useCompleteMealIntakeStore);
