import { ZIP_CODE_REGEX } from 'common/Constants';
import { formatGqlErrors } from 'common/GqlUtilities';
import { Slugs } from 'common/urls/Slugs';
import qs from 'query-string';
import { useCallback, useMemo, useState } from 'react';
import { ZipCodeAvailabilityDocument, ZipCodeAvailabilityQuery } from 'src/gqlReactTypings.generated.d';
import { MEAL_PLANS_INTAKE_FIRST_STEP } from 'src/pages/consumer/meal-plans/complete-meal-intake/preferences/consts';
import { useMealPlanSurfacingLogic } from 'src/pages/consumer/meal-plans/hooks/useMealPlanSurfacingLogic';
import { useHistory } from 'src/shared/hooks/useHistory';
import { Routes } from 'src/shared/Routes';
import { useGqlClient } from 'src/shared/useGqlClient';
import { EXTRA_SLUGS, getSluggedPath, QUERY_PARAMS } from 'src/shared/utils/RouteUtilities';
import { useCachedZipCode } from 'src/user-preferences/useCachedZipCode';
import { useCurrentUser } from '../shared/hooks/useCurrentUserHook';

interface UseZipCodeInputParams {
  onZipSubmit?: (zipCode: string) => void;
  isMealPlan?: boolean;
  redirectRoute?: string;
  extraSlugs?: EXTRA_SLUGS;
  queryParams?: QUERY_PARAMS;
  redirectOnSubmit?: boolean;
}

export const useZipCodeInput = ({
  extraSlugs = [],
  queryParams = {},
  redirectOnSubmit = true,
  redirectRoute = Routes.CONSUMER_EXPLORE,
  isMealPlan = false,
  onZipSubmit,
}: UseZipCodeInputParams) => {
  const history = useHistory();
  const [cachedZipCode, setCachedZipCode] = useCachedZipCode();
  const { mealPlanIntakeRouteByRegion } = useMealPlanSurfacingLogic();
  const GqlClient = useGqlClient();
  const [currentUser] = useCurrentUser();
  const firstStepRoute = mealPlanIntakeRouteByRegion ?? MEAL_PLANS_INTAKE_FIRST_STEP;

  const [zipCode, setZipCode] = useState(cachedZipCode?.trim() ?? '');

  const isZipValid = useMemo(() => ZIP_CODE_REGEX.test(zipCode), [zipCode]);

  const onChange = useCallback((newZipCode: string) => {
    setZipCode(newZipCode.trim());
  }, []);

  const mealPlanRedirect = (_: string) => {
    const route = qs.parseUrl(firstStepRoute);
    const path = qs.stringifyUrl({
      url: route.url,
      query: { ...route.query, ...queryParams },
    });
    history.push(path);
  };

  const onSubmit = useCallback(async () => {
    if (!isZipValid) return;
    onZipSubmit?.(zipCode);

    setCachedZipCode(zipCode);

    if (!redirectOnSubmit) return;

    const { data, errors } = await GqlClient.query<ZipCodeAvailabilityQuery>({
      query: ZipCodeAvailabilityDocument,
      variables: { zipCode },
      fetchPolicy: 'network-only',
    });

    if (errors) {
      console.error(formatGqlErrors(errors));
    }

    const route = getSluggedPath(redirectRoute, [{ slug: Slugs.ZIP_CODE, value: zipCode }, ...extraSlugs], queryParams);

    setZipCode('');
    if (isMealPlan && data && data.zipCode) {
      mealPlanRedirect(data.zipCode.zipCode);
    } else if (redirectRoute === firstStepRoute && ![1, 2, 5, 6, 8, 13].includes(data.zipCode?.region?.id ?? 0)) {
      /**
       * Hack for meal-plans test
       * If the users region isn't one of the 6 major regions (including New Jersey)
       * Redirect them to the waitlist page
       */
      history.push(Routes.WAITLIST_HOME);
    } else if (data.zipCode?.region?.isInWaitlist && !currentUser) {
      history.push(getSluggedPath(Routes.CONSUMER_REGISTER, [{ slug: Slugs.REDIRECT, value: route }]));
    } else {
      history.push(route);
    }
  }, [isZipValid, onZipSubmit, zipCode, GqlClient, redirectRoute, extraSlugs, queryParams, currentUser, history]);

  return {
    cachedZipCode,
    zipCode,
    onSubmit,
    onChange,
  };
};
